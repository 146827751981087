import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { LogoOutline } from "../../../../homepage/src/images/LogoOutline";
import useIsMobile from "../../hooks/useIsMobile";
import MobileHome from "../MobileHome";

const useStyles = makeStyles(() => ({
  main: {
    position: "absolute",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
  },
  body: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  boxStyle: {
    position: "absolute",
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #242422",
    backgroundColor: "#fff",
    Index: 1,
    transition: "all .5s ease-in-out",
    transitionProperty: "height, width, transform",
    "&:hover": {
      "& $separator": {
        background: "#000",
      },
      "& $link": {
        color: "000",
      },
    },
  },
  homeEntertainment: {
    left: 0,
    top: 0,
    right: "auto",
    bottom: "auto",
    width: "60%",
    height: "40%",
  },
  homeAdv: {
    left: "auto",
    top: 0,
    right: 0,
    bottom: "auto",
    display: "flex",
    width: "40%",
    height: "60%",
  },
  homeLittleBay: {
    left: 0,
    top: "auto",
    right: "auto",
    bottom: 0,
    width: "40%",
    height: "60%",
    "&:hover": {
      background: "#E7343F"
    },
  },
  homeRent: {
    left: "auto",
    top: "auto",
    right: 0,
    bottom: 0,
    width: "60%",
    height: "40%",
    "&:hover": {
      background: "#ed6a34"
    },
  },
  logo: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "40%",
    top: "40%",
    width: "20%",
    height: "20%",
    paddingBottom: 9,
    backgroundColor: "#242422",
  },
  separator: {
    width: 80,
    height: 6,
    display: "inline-block",
    marginBottom: 24,
    background: "#242422",
  },
  separatorEnterainment: {
    background: "#F2E61A",
  },
  separatorAdv: {
    background: " #00C8C8",
  },
  separatorRent: {
    background: "#E7343F",
  },
  link: {
    color: "#000",
    fontFamily: "Agrandir-Heavy",
    letterSpacing: -2.5,
    fontSize: 40,
    margin: 0,
    textTransform: "uppercase",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  enthovered1: {
    width: "70%",
    height: "50%",
    background: "#F2E61A",
  },
  enthovered2: {
    width: "44%",
    height: "52%",
  },
  enthovered3: {
    width: "75%",
    height: "40%",
  },
  enthovered4: {
    width: "56%",
    height: "24%",
  },
  enthovered5: {
    width: "28%",
    height: "72%",
  },
  advhovered1: {
    width: "30%",
    height: "70%",
  },
  advhovered2: {
    width: "56%",
    height: "72%",
    background: " #00C8C8",
  },
  advhovered3: {
    width: "25%",
    height: "60%",
  },
  advhovered4: {
    width: "44%",
    height: "44%",
  },
  advhovered5: {
    width: "72%",
    height: "32%",
  },
  lithovered1: {
    width: "50%",
    height: "50%",
  },
  lithovered2: {
    width: "24%",
    height: "48%",
  },
  lithovered3: {
    width: "55%",
    height: "60%",
    background: "#242422",
  },
  lithovered4: {
    width: "36%",
    height: "76%",
  },
  lithovered5: {
    width: "68%",
    height: "28%",
  },
  renthovered1: {
    width: "50%",
    height: "30%",
  },
  renthovered2: {
    width: "76%",
    height: "28%",
  },
  renthovered3: {
    width: "45%",
    height: "40%",
  },
  renthovered4: {
    width: "64%",
    height: "56%",
  },
  renthovered5: {
    width: "32%",
    height: "68%",
  },
  logohovered1: {
    transform: "translate3d(50%, 50%, 0)",
  },
  logohovered2: {
    transform: "translate3d(-80%, 60%, 0)",
  },
  logohovered3: {
    transform: "translate3d(75%, 0, 0)",
  },
  logohovered4: {
    transform: "translate3d(-20%, -80%, 0)",
  },
  logohovered5: {
    transform: "translate3d(-30%, -20%, 0)",
    width: "40%",
    height: "40%",
  },
  comingSoon: {
    fontSize: 14,
    textTransform: "uppercase",
  },
}));

export const App = () => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(0);
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <MobileHome />
  ) : (
    <div className={classes.main}>
      <div className={classes.body}>
        <a
          href="https://entertainment.verteego.it"
          onMouseEnter={() => setHovered(1)}
          className={clsx(classes.boxStyle, classes.homeEntertainment, {
            [classes.enthovered1]: hovered === 1,
            [classes.enthovered2]: hovered === 2,
            [classes.enthovered3]: hovered === 3,
            [classes.enthovered4]: hovered === 4,
            [classes.enthovered5]: hovered === 5,
          })}
        >
          <div
            className={clsx(classes.separator, classes.separatorEnterainment)}
          />
          <h2 className={classes.link}>Entertainment</h2>
        </a>
        <a
          href="https://ad.verteego.it"
          onMouseEnter={() => setHovered(2)}
          className={clsx(classes.boxStyle, classes.homeAdv, {
            [classes.advhovered1]: hovered === 1,
            [classes.advhovered2]: hovered === 2,
            [classes.advhovered3]: hovered === 3,
            [classes.advhovered4]: hovered === 4,
            [classes.advhovered5]: hovered === 5,
          })}
        >
          <div className={clsx(classes.separator, classes.separatorAdv)} />
          <h2 className={classes.link}>Advertising</h2>
        </a>
        <a
          href="https://rent.verteego.it"
          onMouseEnter={() => setHovered(3)}
          className={clsx(classes.boxStyle, classes.homeLittleBay, {
            [classes.lithovered1]: hovered === 1,
            [classes.lithovered2]: hovered === 2,
            [classes.lithovered3]: hovered === 3,
            [classes.lithovered4]: hovered === 4,
            [classes.lithovered5]: hovered === 5,
          })}
        >
          <div className={clsx(classes.separator, classes.separatorRent)} />
          <h2 className={classes.link}>Rent</h2>
        </a>
        <a
          href="https://littlebay.verteego.it"
          onMouseEnter={() => setHovered(4)}
          className={clsx(classes.boxStyle, classes.homeRent, {
            [classes.renthovered1]: hovered === 1,
            [classes.renthovered2]: hovered === 2,
            [classes.renthovered3]: hovered === 3,
            [classes.renthovered4]: hovered === 4,
            [classes.renthovered5]: hovered === 5,
          })}
        >
          <div className={clsx(classes.separator)} />
          <h2 className={classes.link}>Little Bay</h2>
        </a>
        <div
          onMouseEnter={() => setHovered(5)}
          className={clsx(classes.boxStyle, classes.logo, {
            [classes.logohovered1]: hovered === 1,
            [classes.logohovered2]: hovered === 2,
            [classes.logohovered3]: hovered === 3,
            [classes.logohovered4]: hovered === 4,
            [classes.logohovered5]: hovered === 5,
          })}
        >
          <LogoOutline />
        </div>
      </div>
    </div>
  );
};
