import { useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";

const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isMobileClasses = ({
    webClass = "",
    mobileClass = "",
    notIsMobileClass = "",
  }) => {
    return clsx(webClass, {
      [mobileClass]: isMobile,
      [notIsMobileClass]: !isMobile,
    });
  };

  return {
    isMobile,
    isMobileClasses,
  };
};

export default useIsMobile;
