import { createMuiTheme } from "@material-ui/core/styles";
import { ColorType } from "./themeTypes";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    colors: ColorType;
  }
  interface ThemeOptions {
    colors: ColorType;
  }
}

export const theme = (darkMode: "dark" | "light", isMobile?: boolean) =>
  createMuiTheme({
    palette: {
      type: darkMode,
      primary: {
        main: "#FFFFFF",
      },
      secondary: {
        main: "#000000",
      },
    },
    colors: {
      white: "#FFFFFF",
      red: "#E7343F",
      black: "#242422",
    },
    typography: {
      fontFamily: [
        "Agrandir",
        "Agrandir-Heavy",
        "Agrandir-Bold",
        "Agrandir-Wide",
        "Agrandir-Medium",
        "Agrandir-WideBlack",
        "Agrandir-Tight",
        "Salted",
      ].join(","),
      fontWeightRegular: 400,
      fontWeightBold: 700,
      h1: {
        fontSize: 48,
      },
      h2: {
        fontSize: 36,
      },
      h3: {
        fontSize: 30,
      },
      h4: {
        fontSize: 24,
      },
      h5: {
        fontSize: 20,
      },
      h6: {
        fontSize: 18,
      },
      subtitle1: {
        fontSize: 16,
      },
      subtitle2: {
        fontSize: 14,
      },
      caption: {
        fontSize: 12,
        textTransform: "uppercase",
      },
      button: {
        fontSize: 14,
        lineHeight: "normal",
        fontWeight: "bold",
        fontFamily: "Agrandir-Bold",
      },
    },
  });
