import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

export const LogoOutline = (props: Props) => {
  return (
    <svg width="141" height="141" viewBox="0 0 141 141" fill="none" {...props}>
      <path
        d="M70.5074 8.86816C75.8746 8.86472 81.1898 9.91933 86.1491 11.9717C91.1084 14.024 95.6145 17.0339 99.4097 20.829C103.205 24.6242 106.215 29.1303 108.267 34.0896C110.319 39.0489 111.374 44.3641 111.371 49.7313V76.5399H98.9091V105.533H76.988V132.132H29.6291V56.1273C29.6291 55.0078 29.6539 53.7864 29.7048 52.4095C29.7737 50.7909 29.9114 49.1819 30.0264 47.9605C30.4651 43.5549 31.2588 39.1919 32.3995 34.9139C35.3532 27.3476 40.4885 20.8296 47.1536 16.1874C54.0246 11.3997 62.1004 8.86816 70.5074 8.86816ZM70.5074 8.04199C52.7895 8.04199 37.6533 19.0872 31.6126 34.6571C30.4529 38.9921 29.6471 43.414 29.2029 47.8793C29.0652 49.3616 28.9406 50.8597 28.8787 52.3744C28.8332 53.6095 28.8022 54.8611 28.8022 56.1273V132.961H77.8135V106.359H99.7325V77.3661H112.194V49.7313C112.194 44.2565 111.116 38.8353 109.021 33.7772C106.926 28.7191 103.855 24.1232 99.984 20.2519C96.1128 16.3806 91.5169 13.3098 86.4588 11.2149C81.4007 9.11986 75.9794 8.04172 70.5046 8.04199H70.5074Z"
        fill="white"
      />
      <path
        d="M99.2092 53.686C99.0406 53.9799 98.854 54.2884 98.6543 54.581C97.2794 56.6601 95.4382 58.3898 93.2773 59.6323C90.8559 61.0529 88.0984 61.7996 85.291 61.7948C83.1403 61.8059 81.0108 61.3708 79.0369 60.517C78.4275 60.2708 77.8386 59.9765 77.2757 59.6372C74.8116 58.214 72.77 56.1613 71.3604 53.6894C72.7637 51.2058 74.8049 49.142 77.273 47.7114C77.8432 47.3829 78.4321 47.0881 79.0369 46.8287C79.4183 46.6658 79.8066 46.5171 80.2018 46.3826C79.4862 47.3175 79.0452 48.4332 78.9281 49.6047C78.9281 49.7135 78.9136 49.8202 78.9136 49.9289C78.9006 49.9849 78.8951 50.0423 78.8971 50.0997V50.2684C78.8971 50.4549 78.9136 50.6401 78.9281 50.8253V50.9176C79.0905 52.5193 79.8521 54.0005 81.0601 55.0646C82.2682 56.1287 83.8337 56.6971 85.443 56.6561C87.0524 56.6151 88.5869 55.9677 89.7392 54.8435C90.8915 53.7192 91.5766 52.2012 91.6573 50.5933C91.6694 50.4854 91.6742 50.3769 91.6718 50.2684C91.6739 50.155 91.669 50.0417 91.6573 49.9289C91.5969 48.637 91.1402 47.3949 90.3492 46.3716C91.3679 46.7157 92.348 47.1647 93.2739 47.7114C95.4351 48.96 97.276 50.6946 98.6509 52.7779C98.8505 53.0691 99.0371 53.3775 99.2092 53.686Z"
        fill="white"
      />
    </svg>
  );
};
